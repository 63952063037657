import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Modal, CloseButton, Button, Spinner } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';
import moment from 'moment';
import { CreateArea } from './CreateArea';
import { EditArea } from './EditArea';
import { DisabledArea } from './DisabledArea';
import { BitacoraArea } from './BitacoraArea';
import { columnsArea, columnsBitacora } from '../data';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useLoadingContext from 'hooks/useLoadingContext';

const AreaAdmin = () => {
  const navigate = useNavigate();

  const {user} = useAuth();
  const { isLoading, setIsLoading} = useLoadingContext();
  const [ readyContent, setReadyContent ] = useState(false);
  const [ readyContentBitacora, setReadyContentBitacora ] = useState(false);

  const [ areas, setAreas] = useState([]);
  const [ areaSelect, setAreaSelect] = useState([]);
  const [ deleteArea, setDeleteArea] = useState();

  const [ bitacoraIndividual, setBitacoraIndividual] = useState([]);
  // MODAL
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showBitacoraGeneralModal, setShowBitacoraGeneralModal] = useState(false);
  const [showBitacoraIndividualModal, setShowBitacoraIndividualModal] = useState(false);
  const [showDisabledModal, setShowDisabledModal] = useState(false);

  const startResetApp = async () => {
    getAllAreas();
  }

  const getAllAreas = async () => {
    setReadyContent(false);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/area/getAllAreas`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      if( response.status !== 200 ) return;
      const list = response.data.data
      list.forEach((element) => {
        element.acciones = [
          <IconButton
            title="Editar"
            variant="white"
            size="sm"
            icon="edit"
            style={{ boxShadow: "none", width: "33%" }}
            onClick={() => {
              setShowEditModal(true)
              setAreaSelect(element)
            }}
          ></IconButton>,
          <IconButton
          title="Deshabilitar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setShowDeleteModal(true)
            setDeleteArea(element.id)
          }}
          ></IconButton>,
          <IconButton
          title="Ver Bitácora"
          variant="white"
          size="sm"
          icon="book"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setBitacoraIndividual([]);
            setShowBitacoraIndividualModal(true)
            getBitacoraIndividual(element.id)
          }}
          ></IconButton>
        ]
      })
      setReadyContent(true)
      setAreas(list);
    })
  }

  const getBitacoraIndividual = async (id) => {
    setReadyContentBitacora(false);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/area/getBitacoraById/${id}`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      if(response.data.statusCode === 200){
        const list =  response.data.data;
        list.forEach(element => {
          element.fecha = moment(element.fecha_transaccion).format('DD-MM-YYYY');
          element.hora = moment(element.fecha_transaccion).format('hh:mm:ss');
        })
        setBitacoraIndividual(list);
        setReadyContentBitacora(true);
      }
    });
  }

  //? INICIANDO LA APP
  useEffect(() => {
    const hasAccess = verifyAccess(user, 'profileAreaAdmin', '/administrador/dashboard')
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) startResetApp();
  }, [])

  const handleChange = (data) => {
    startResetApp()
  }

  // DESHABILITAR AREA
  const handleDelete = async () => {
    setIsLoading(true);
    await axios.delete(`${process.env.REACT_APP_ADMIN_SERVICE}/area/${deleteArea}/${user.id}`)
    .then((response) => {
      setIsLoading(false);
      if( verifyResponseUnauthorizedFetch(response, 4) ) return;
      setReadyContent(false);
      startResetApp()
      setShowDeleteModal(false)
      toastNotification('success', 'Área deshabilitada correctamente')
    })
    .catch((err) => {
      setIsLoading(false);
      setReadyContent(true);
      toastNotification('error', 'Ocurrió un error al deshabilitar el área')
      setTimeout(() => {
        setShowDeleteModal(false);
      }, 2000);
    })
  };

  return (
    <> 
        <div>
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="justify-content-between">
              <Col lg={6} className="ps-lg-4 my-5text-lg-start">
                <h3 className="text-primary">Áreas</h3>
              </Col>
            </Row>

            { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
              <Card className="h-md-100">
                <Card.Body>
                <AdvanceTableWrapper
                columns={columnsArea}
                data={areas}
                sortable
                pagination
                perPage={10}
              >

                <Row className="mb-3">
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-success"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => setShowCreateModal(true)}
                      >
                        Nueva Área
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="book"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowBitacoraGeneralModal(true)
                        }}
                      >
                        Bitácora General
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-warning"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowDisabledModal(true)
                        }}
                      >
                        Deshabilitados
                      </IconButton>
                    </div>
                  </Col>
                </Row>
                
                <AdvanceTable
                  title='Áreas'
                  subtitle='Tabla de Área'
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <div className="mt-3">
                  <AdvanceTableFooter
                    rowCount={areas.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </div>
              </AdvanceTableWrapper>
                </Card.Body>
              </Card>
            )}
          </Card.Body>
        </div>
      {/* MODAL CREAR AREA */}
      <div>
        <Modal
            show={showCreateModal}
            centered
            onHide={() => setShowCreateModal(false)}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Crear Área
              </Modal.Title>
              <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowCreateModal(false)}
              />
            </Modal.Header>
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                    <CreateArea handleChange={handleChange} setShowCreateModal={setShowCreateModal} />
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
      </div>
      {/* MODAL EDITAR AREA */}
      <div>
        <Modal
          show={showEditModal}
          centered
          onHide={() => setShowEditModal(false)}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Editar Área
            </Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setShowEditModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-0">
            <Card>
              <Card.Body className="fs--1 fw-normal p-4">
                <EditArea data={areaSelect} handleChange={handleChange} setShowEditModal={setShowEditModal} />
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
      {/* MODAL DESHABILITAR AREA */}
      <div>
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Deshabilitar Área</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere deshabilitar el Área?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" 
             disabled={isLoading}
             onClick={handleDelete}
            >
              Deshabilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL BITACORA INDIVIDUAL */}
      <div>
        <Modal
          size='xl'
          show={showBitacoraIndividualModal}
          onHide={() => setShowBitacoraIndividualModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Bitácora Individual</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          { !readyContentBitacora ? 
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
          : (
            <>
            { !bitacoraIndividual.length > 0 ? (
                  <h6>No hay historial en la Bitácora</h6>
                ) :
                (
                  <div>
                    <AdvanceTableWrapper
                        columns={columnsBitacora}
                        data={bitacoraIndividual}
                        sortable
                        pagination
                        perPage={10}
                      >            
                        <AdvanceTable
                          title='Bítacora de Áreas'
                          subtitle='Tabla Bítacora de Áreas'
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                          }}
                        />
                        <div className="mt-3">
                          <AdvanceTableFooter
                            rowCount={bitacoraIndividual.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                          />
                        </div>
                      </AdvanceTableWrapper>
                    </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowBitacoraIndividualModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL BITACORA GENERAL */}
      <div>
        <Modal
          size='xl'
          show={showBitacoraGeneralModal}
          onHide={() => setShowBitacoraGeneralModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Bitácora General</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BitacoraArea />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowBitacoraGeneralModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL DISABLED */}
      <div>
        <Modal
          size='xl'
          show={showDisabledModal}
          onHide={() => setShowDisabledModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Áreas Deshabilitadas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DisabledArea startResetApp={startResetApp} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                setShowDisabledModal(false)
                startResetApp()
              }}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default AreaAdmin