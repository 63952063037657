import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Spinner } from 'react-bootstrap';

import moment from 'moment';
import axios from 'utils/axios';

import StatisticsCard from 'components/dashboards/stats-cards/StatisticsCard';
import BasicBarChart from 'components/common/BasicBarChart'

import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import { toastNotification } from 'helpers/utils';
import { TableWrapper } from 'pages/common/components/TableWrapper';
import { FiltrosComponent } from './components/FiltrosComponent';
import IconButton from 'components/common/IconButton';

export const columnsConexiones = [
  {
    accessor: 'username',
    Header: 'Usuario'
  },
  {
    accessor: 'userrut',
    Header: 'Rut'
  },
  {
    accessor: 'cargo',
    Header: 'Cargo'
  },
  {
    accessor: 'perfil',
    Header: 'Perfil'
  },
  {
    accessor: 'cantidad_conexiones',
    Header: 'Cantidad de Conexiones'
  },
  {
    accessor: 'fecha_login',
    Header: 'Último Inicio de Sesión'
  },
]

export const ConexionesUsuarios = () => {

  const [ readyContent, setReadyContent ] = useState(false);

  const [ totalUsers, setTotalUsers ] = useState(0);
  const [ activatedUsers, setActivatedUsers ] = useState(0);
  const [ disabledUsers, setDisabledUsers ] = useState(0);
  const [ perfiles, setPerfiles ] = useState([]);
  const [ cargos, setCargos ] = useState([]);
  const [ configFilters, setConfigFilters ] = useState({ perfilSelected: 0, cargoSelected: 0, desde: '', hasta: '' });

  const [ usersConections, setUsersConections ] = useState([]);
  const [ dataToTable, setDataToTable ] = useState([]);

  useEffect(() => startResetApp(), []);

  const startResetApp = async () => {
    setReadyContent(false);

    try {

      const [ respSummaryUsers, respConexionesUsers, respPerfiles, respCargos ] = await Promise.all([
        axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/usuario/getSummaryUsers`),
        axios.post(`${process.env.REACT_APP_ADMIN_SERVICE}/estadisticas/getConnectionsOfUsers`),
        axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/getAllPerfilesByState`),
        axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/cargo/getAllCargosByState`),
      ]);
      
      setTotalUsers(respSummaryUsers.data.data.totalUsers);
      setActivatedUsers(respSummaryUsers.data.data.usersActived);
      setDisabledUsers(respSummaryUsers.data.data.usersDisabled);
      setPerfiles(respPerfiles.data.data);
      setCargos(respCargos.data.data);
      setUsersConections(respConexionesUsers.data.data.map( val => { return { name: val.username, value: Number(val.cantidad_conexiones) } } ));
      formatDataToTable(respConexionesUsers.data.data);    
    } catch (error) {
      toastNotification('warning', 'Ocurrio un error al intentar obtener la estadistica de conexiones')
      console.error(error);
    }

    setReadyContent(true);
  };

  const clearFilters = () => setConfigFilters({ perfilSelected: 0, cargoSelected: 0, desde: '', hasta: '' });

  const handleConfigFilters = ({ perfil, cargo, dateDesde, dateHasta }) => {
    setConfigFilters({
      perfilSelected: perfil !== undefined ? perfil : configFilters.perfilSelected,
      cargoSelected: cargo !== undefined ? cargo : configFilters.cargoSelected,
      desde: dateDesde !== undefined ? dateDesde : configFilters.desde,
      hasta: dateHasta !== undefined ? dateHasta : configFilters.hasta,
    })
  }

  const getAccessOfUsersByFilters = async () => {
    try {
      const body = {};
      if( configFilters.perfilSelected > 0 ) body.idPerfil = configFilters.perfilSelected;
      if( configFilters.cargoSelected > 0 ) body.idCargo = configFilters.cargoSelected;
      if( configFilters.desde !== '' ) body.startDate = configFilters.desde;
      if( configFilters.hasta !== '' ) body.endDate = configFilters.hasta;

      const { data } = await axios.post(`${process.env.REACT_APP_ADMIN_SERVICE}/estadisticas/getConnectionsOfUsers`, body);
      setUsersConections(data.data.map( val => { return { name: val.username, value: Number(val.cantidad_conexiones) } } ));
    } catch (error) {
      toastNotification('warning', 'Ocurrio un error al intentar obtener los datos de acceso segun los filtros')
      console.error(error);
    }
  };

  const formatDataToTable = ( conexionesUsers ) => {

    const data = conexionesUsers.map( con => {
      const obj = {...con};
      obj.fecha_login = ( <p className='text-warning fs--1 p-0 m-0 text-center'>{ moment(obj.fecha_login).format('DD/MM/YYYY  h:mm:ss a') }</p> )
      obj.cantidad_conexiones = ( <p className='text-success fs--1 p-0 m-0 text-center fw-bold'>{ obj.cantidad_conexiones }</p> )
      obj.perfil = ( <p className="text-capitalize fs--1 p-0 m-0">{ obj.perfil }</p> )
      obj.cargo = ( <p className="text-capitalize fs--1 p-0 m-0">{ obj.cargo }</p> )
      return obj;
    })

    setDataToTable(data);
    
  }

  const statsData = [
    {
      title: 'Usuarios en el Sistema',
      value: totalUsers,
      decimal: false,
      suffix: '',
      prefix: '',
      link: '',
      linkText: '',
      valueClassName: 'text-info',
      image: bg2
    },
    {
      title: 'Usuarios Habilitados',
      value: activatedUsers,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-success',
      link: '',
      linkText: '',
      image: bg3
    },
    {
      title: 'Usuarios Deshabilitados',
      value: disabledUsers,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '',
      linkText: '',
      image: bg1
    },
  ];

  return (
    <>
      { !readyContent 
        ? ( <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> )
        : (

          <Card.Body className="overflow-hidden p-lg-6">

            <Row className="justify-content-between mb-4">
              <Col>
                  <h3 className="text-primary">Estadísticas Conexiones de Usuarios</h3>
              </Col>
            </Row>

            {/* Resumen de Usuarios */}
            <Row className="g-3 mb-3">
              {statsData.map((stat, index) => (
                <Col key={stat.title} sm={index === 2 ? 12 : 6} md={4}>
                  <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                </Col>
              ))}
            </Row>

            
            {/* Grafico de Barra y Torta */}
            <Row className="g-3 mb-3">

              <Col key="graficoBarra" >
                <Card>
                  <Card.Header className='fs--1 d-flex justify-content-between'>
                    <div>Graficos de accesos de usuarios</div>
                    <div className='text-end'>
                        <IconButton
                            variant="falcon-success"
                            size="sm"
                            icon="search"
                            transform="shrink-3"
                            className="me-2 mb-2"
                            onClick={getAccessOfUsersByFilters}
                        >
                            Buscar
                        </IconButton>
                        <IconButton
                            variant="falcon-info"
                            size="sm"
                            icon="trash"
                            transform="shrink-3"
                            className="me-2 mb-2"
                            onClick={clearFilters}
                        >
                            Limpiar
                        </IconButton>
                    </div>
                </Card.Header>
                  <Card.Body className='p-3'>

                  <Row className="mb-4">
                    <FiltrosComponent
                      key={'filtrosConfig'}
                      perfiles={perfiles}
                      cargos={cargos}
                      configFilters={configFilters}
                      handleConfigFilters={handleConfigFilters}
                    />
                  </Row>

                    <BasicBarChart 
                      key={'graficoAccesos'} 
                      data={usersConections} 
                      labelAxisX='Usuarios' 
                      labelAxisY='Cantidad de Accesos' 
                    />
                  </Card.Body>
                </Card>
              </Col>

            </Row>

            {/* Tabla de conexiones de usuarios  */}
            <Row className="g-3 mb-3">
              <Card>
                <Card.Body>

                  <TableWrapper
                    key={'Tabla'}
                    columnas={columnsConexiones}
                    data={dataToTable}
                    elementosPorPagina={10}
                    titleToExport={'Accesos de Usuarios'}
                    subtitleToExport={'Tabla de Accesos de Usuarios'}
                  />
                  
                </Card.Body>
              </Card>
            </Row>

          </Card.Body>
        )
      }

    </>
  )
}
