import { useEffect, useState } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { toastNotification } from "helpers/utils";
import axios from "utils/axios";
import FalconCardHeader from "components/common/FalconCardHeader";

export const ConfiguracionGraficosUser = ({ titulo = '', accesosGraficos = [], setAccesosGraficos, idToUpdate = 0}) => {

    const [ graficos, setGraficos ] = useState([]);
    const [ readyContent, setReadyContent ] = useState(false);

    useEffect(async () => {
        try {
            await Promise.all([getAccessToGraphics(), getGraphics(),]);
            setReadyContent(true);
        } catch (error) {
            console.error('Ocurrió un error al intentar obtener la configuración de gráficos y accesos');
            toastNotification('warning', 'Error al intentar obtener la configuración de gráficos y accesos');
        }
    }, []);

    const getGraphics = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/grafico/getGraphics`);
            setGraficos(data.data);
        } catch (error) {
            console.error('Ocurrió un error al intentar consultar por la configuracion de graficos')
            toastNotification('warning', 'Error al intentar obtener la configuración de gráficos')
        }
        setReadyContent(true);
    };

    const getAccessToGraphics = async () => {
        if( idToUpdate === 0 ) return;
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/grafico/getAccessToGraphicsByIdUser/${idToUpdate}`);
            setAccesosGraficos(data.data);
        } catch (error) {
            console.error('Ocurrió un error al intentar consultar por la configuracion de graficos del usuario')
            toastNotification('warning', 'Error al intentar obtener la configuración de gráficos del usuario')
        }
    };


    const handleAccesoGraphic = ( idGraphic ) => {
        const checked = accesosGraficos.includes(idGraphic);
        ( !checked ) ? setAccesosGraficos([...accesosGraficos, idGraphic]) : setAccesosGraficos(accesosGraficos.filter(( id ) => id !== idGraphic ));
    };

    const renderCheckBox = () => {
        return graficos.map( grafico => (
                <Form.Check 
                    type='checkbox'
                    id={grafico.id}
                    label={grafico.nombre}
                    onClick={() => handleAccesoGraphic(grafico.id)}
                    checked={accesosGraficos.includes(grafico.id)}
                />
            ) )
    }

    return (
        <Card className="mt-4">
            <FalconCardHeader
                title={titulo}
                titleTag="h6"
                className="py-2"
                light
            />
            <Card.Body className="mt-2">
                { !readyContent 
                  ? (<div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> )
                  : renderCheckBox()
                }
            </Card.Body>
    
        </Card>
    )
};

ConfiguracionGraficosUser.propTypes = {
    titulo: PropTypes.string.isRequired,
    accesosGraficos: PropTypes.arrayOf(PropTypes.number).isRequired,
    setAccesosGraficos: PropTypes.func.isRequired,
    idToUpdate: PropTypes.number
};

ConfiguracionGraficosUser.defaultProps = {
    titulo: '',
    accesosGraficos: [],
    idToUpdate: 0
};