
export const columns = [
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },
  {
    accessor: 'fechaInicioFormat',
    Header: 'Fecha Inicio'
  }, 
  {
    accessor: 'fechaTerminoFormat',
    Header: 'Fecha Término'
  },
  {
    accessor: 'accion',
    Header: 'Acciones'
  }
];

export const columns2 = [
  {
    accessor: 'tipoCosto',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcionCorta',
    Header: 'Descripción '
  },
  {
    accessor: 'valorFormat',
    Header: 'Valor'
  },
  {
    accessor: 'diaCosto',
    Header: 'Día Costo'
  },
  {
    accessor: 'periodo',
    Header: 'Período'
  },
];

export const columns3 = [
  {
    accessor: 'tipoCosto',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcionCorta',
    Header: 'Descripción '
  } ,
  {
    accessor: 'valorFormat',
    Header: 'Valor'
  },
  {
    accessor: 'fechaFormat',
    Header: 'Fecha Costo'
  },
];

export const columnsPropuesta = [
  {
    accessor: 'tipoCostoPropuesta',
    Header: 'Nombre'
  },
  {
    accessor: 'descripcionCorta',
    Header: 'Descripción '
  },
  {
    accessor: 'valorFormat',
    Header: 'Valor'
  },
  {
    accessor: 'diaCosto',
    Header: 'Día Costo'
  },
  {
    accessor: 'periodo',
    Header: 'Período'
  },
];

