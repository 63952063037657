import axios from 'utils/axios';
import { toastNotification } from 'helpers/utils';

export const takeMessageService = async ( idMensaje = 0 ) => {
    if(idMensaje === 0 ) return;

    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/message/takeMessage`;
    try {
        const { data } = await axios.patch(url, { idMensaje });
        if( data.statusCode !== 200 ) {
            toastNotification('warning', 'Ocurrió un error al intentar tomar el mensaje del equipo.')
            console.error(data.message);
            return {};
        }
        return data.data;
    } catch (error) {
        console.error(error);
        toastNotification('error', 'Ocurrió un error al intentar tomar el mensaje del equipo.');
    }
};

export const closeMessage = async ( idMessage = 0, descripcionResolucion = '' ) => {
    
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/message/closeMessage`;

    try {
        const { data } = await axios.patch(url, { idMessage, descripcionResolucion });
        if( data.statusCode !== 200 ) {
            toastNotification('warning', 'Ocurrió un error al intentar cerrar el mensaje.')
            console.error(data.message);
            return {};
        }
        return data.data;
    } catch (error) {
        console.error(error);
        toastNotification('error', 'Ocurrió un error al intentar cerrar el mensaje.');
    }
}