import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActiveUsersChart from '../../components/GraficoPropuestasChart'
import { formatterPesoChile } from "helpers/utils";
import moment from 'moment';

const GraficoReales = ({situacionReal, propuesta, situacionAnterior, fechaInicio, fechaTermino, situacionSelect}) => {

  const valorMaxSituacionReal = Math.max(...situacionReal);
  const valorMaxPropuesta = Math.max(...propuesta);
  const valorMaxSituacionAnterior = Math.max(...situacionAnterior);

  let dateStart = moment(fechaInicio);
  dateStart.set('hour', 4).set('minute', 0).set('second', 0);
  let dateEnd = moment(fechaTermino);
  dateEnd.set('hour', 4).set('minute', 0).set('second', 0);

  
  return (
    <>
      <Card>
        <FalconCardHeader
          title="Costo Acumulado Propuesta vs Situación Anterior vs Situación Real"
          titleTag="h6"
          className="py-2"
          light
        />
        <Card.Body>
          { !situacionSelect ? (
          <h6>No Hay Ninguna Situación Real Anterior seleccionada</h6>
        ) : (        
          <>
            <Row className="text-center mb-3">
              <Col className='pt-3 border-end'>
                <h6 className="fs--2 text-7">
                  <FontAwesomeIcon
                    icon="circle"
                    className={`text-success me-2`}
                  />
                  S/Real
                </h6>
                <h5 className="text-700 fs-0">
                  {( valorMaxSituacionReal > 0) ? (formatterPesoChile(valorMaxSituacionReal)) : ('0')}
                </h5>
              </Col>

              <Col className='pt-3 border-end'>
                <h6 className="fs--2 text-7">
                  <FontAwesomeIcon
                    icon="circle"
                    className={`text-info me-2`}
                  />
                  Prop.
                </h6>
                <h5 className="text-700 fs-0">
                  {( valorMaxPropuesta > 0) ? (formatterPesoChile(valorMaxPropuesta)) : ('0')}
                </h5>
              </Col>

              <Col className='pt-3'>
                <h6 className="fs--2 text-7">
                  <FontAwesomeIcon
                    icon="circle"
                    className={`text-danger me-2`}
                  />
                  S/Ant.
                </h6>
                <h5 className="text-700 fs-0">
                  {( valorMaxSituacionAnterior > 0) ? (formatterPesoChile(valorMaxSituacionAnterior)) : ('0')}
                </h5>
              </Col>
            </Row>

            <Col className="echart-active-users-report-container w-100">
              <ActiveUsersChart 
                situacionReal={situacionReal} 
                propuesta={propuesta} 
                situacionAnterior={situacionAnterior} 
                fechaInicio={fechaInicio} 
                fechaTermino={fechaTermino} 
              />
            </Col>
          </>
        )}
      </Card.Body>
    </Card>
    </>
  );
};

export default GraficoReales;