import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconItem from 'components/common/icon/IconItem';
import { formatterPesoChile } from "helpers/utils";
import FalconCardHeader from 'components/common/FalconCardHeader';

const StatsItem = ({ situacionReal, propuesta, situacionAnterior }) => {
  const [percent, setPercent] = useState(0);
  const [percentReal, setPercentReal] = useState(0);
  
  const valorMaxPropuesta = Math.max(...propuesta);
  const valorMaxSituacionAnterior = Math.max(...situacionAnterior)
  const valorMaxSituacionReal = Math.max(...situacionReal)
  
  useEffect(() => {
    calcularPercentAnterior( valorMaxPropuesta, valorMaxSituacionAnterior);
    calcularPercentReal(valorMaxSituacionReal, valorMaxSituacionAnterior )
  }, [propuesta, situacionAnterior, situacionReal])

  const calcularPercentAnterior = (prop, ant) => {
    let porcentaje
    if( prop > ant){
      porcentaje = ((ant * 100) / prop);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercent(Number(+porcentaje))
    }else{
      porcentaje = ((prop * 100) / ant);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercent(Number(+porcentaje))
    }
  }

  const calcularPercentReal = (real, ant) => {
    let porcentaje
    if( real > ant){
      porcentaje = ((ant * 100) / real);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercentReal(Number(+porcentaje))
    }else{
      porcentaje = ((real * 100) / ant);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercentReal(Number(+porcentaje))
    }
  }
  

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        className="my-2 position-static"
      >
        <IconItem
          tag="div"
          icon='file'
          bg={`soft-primary`}
          color='primary'
          size="sm"
          iconClass="fs--2"
          className="me-2 shadow-none"
        />
        <h6 className="mb-0 flex-1">Propuesta vs Situación Anterior</h6>
      </Flex>
      <Flex>
      <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{isNaN(percent) ? '0': percent}%</p>
        <div className="d-flex flex-column mb-4">
        { (valorMaxPropuesta > valorMaxSituacionAnterior) ? (
                <FontAwesomeIcon
                  icon='caret-up'
                  className={`me-1 mb-0 text-danger`}
                />
                ) : (
                  <FontAwesomeIcon
                  icon='caret-down'
                  className={`me-1 mb-0 text-success`}
                />
              ) }
              <p className="fs--2 mb-0 mt-0 text-nowrap">{isNaN(percent) ? (
                '0 vs 0'
              ) : (
                <p className='fs--1'>
                  <span className='fw-bold me-1'>{formatterPesoChile(valorMaxPropuesta)}</span>
                  vs
                  <span className='fw-bold ms-1'>{formatterPesoChile(valorMaxSituacionAnterior)}</span>
                </p>
              )}
              </p>
        </div>
      </Flex>

      <Flex
        justifyContent="center"
        alignItems="center"
        className="mb-2 position-static"
      >
        <IconItem
          tag="div"
          icon='file'
          bg={`soft-success`}
          color='success'
          size="sm"
          iconClass="fs--2"
          className="me-2 shadow-none"
        />
        <h6 className="mb-0 flex-1">Real vs Situación Anterior</h6>
      </Flex>
      <Flex>
      <p className="font-sans-serif lh-1 fs-4 pe-2">{isNaN(percentReal) ? '0': percentReal}%</p>
        <div className="d-flex flex-column mb-2">
        { (valorMaxSituacionReal > valorMaxSituacionAnterior) ? (
                <FontAwesomeIcon
                  icon='caret-up'
                  className={`me-1 mb-0 text-danger`}
                />
                ) : (
                  <FontAwesomeIcon
                  icon='caret-down'
                  className={`me-1 mb-0 text-success`}
                />
              ) }
              <p className="fs--2 mb-0 mt-0 text-nowrap">{isNaN(percentReal) ? (
                '0 vs 0'
              ) : (
                <p className='fs--1'>
                  <span className='fw-bold me-1'>{formatterPesoChile(valorMaxSituacionReal)}</span>
                  vs
                  <span className='fw-bold ms-1'>{formatterPesoChile(valorMaxSituacionAnterior)}</span>
                </p>
              )}
              </p>
        </div>
      </Flex>
    </>
  );
};

const CrmStats = ({situacionReal, propuesta, situacionAnterior}) => {
  return (
    <Card>
      <FalconCardHeader
        title="Ahorro Actual"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body>
        <Row>
              <StatsItem 
                situacionReal={situacionReal}
                propuesta={propuesta}
                situacionAnterior={situacionAnterior}   
              />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CrmStats;
