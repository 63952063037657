import React, { useState, useEffect } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import axios from 'utils/axios';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

import { columnsCostosSinAccion } from './data';
import { capitalizarString } from 'helpers';
import { formatterPesoChile } from 'helpers/utils';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import ReactTooltip from 'react-tooltip';


export const DetallePropuesta = ({ tiposCostos, tiposEstados, propuestaToSee, showDetallePropuestaModal, setShowDetallePropuestaModal, setPropuestaToSee  }) => {
    registerLocale('es', es)

    //! DATOS PARA EL FORMULARIO
    const [idEstadoPropuesta, setIdEstadoPropuesta] = useState(propuestaToSee.idEstadoPropuesta.id);
    const [periodo, setPeriodo] = useState(propuestaToSee.periodo);
    const [nombre, setNombre] = useState(propuestaToSee.nombre);
    const [idEquipo, setIdEquipo] = useState(propuestaToSee.idEquipo.id);
    const [descripcion, setDescripcion] = useState(propuestaToSee.descripcion);

    const [formDisabled, setFormDisabled] = useState(true);
    
    //! COSTOS PROPUESTA( IDENTICO A BD ) - COSTOS PROPUESTA ACTIONS( CON BOTONES PARA LA TABLA )
    const [costosPropuesta, setCostosPropuestas] = useState([]);
    const [costosPropuestaActions, setCostosPropuestaActions] = useState([]);

    //! ITEM PARA ENVIAR AL MODAL AL ACTUALIZAR

    const [validated, setValidated] = useState(false);

    const [habilitarButton, setHabilitarButton] = useState(true);


    //? OBTENEMOS LOS COSTOS DE LA PROPUESTA
    useEffect(async () => {
        getCostosPropuestaById(propuestaToSee.id);
        if( idEstadoPropuesta == 4 || idEstadoPropuesta == 5 ) setFormDisabled(true)
    }, [])


    //? TOMAMOS UNA COPIA DEL OBJETO DE COSTOS Y CREAMOS UNO ADAPTADO A LA TABLA
    useEffect( async () => {
        async function saveNewsActions() {
            let costosActions = costosPropuesta.map( c => {
                let costo = tiposCostos.find(tipoCosto => tipoCosto.id === c.idTipoCosto)
                c.nameTipoCosto = capitalizarString(costo.nombre);
                c.valor = formatterPesoChile(c.valor);
                c.descripcionCorta = <div data-tip data-for={`tooltipDescripcion-${c.id}`} >
                    <ReactTooltip id={`tooltipDescripcion-${c.id}`} className={'tooltipReact w-auto'} delayShow={50} >{ c.descripcion }</ReactTooltip>
                    { c.descripcion.split(" ").length > 2 ? c.descripcion.split(" ").slice(0,2).join(' ') + '...' : c.descripcion }
                </div>
                return c;
            })
            setCostosPropuestaActions(costosActions)
        }
        await saveNewsActions();
    }, [costosPropuesta] )


    //! OBTENEMOS LOS COSTOS POR EL ID DE LA PROPUESTA POR SU ID
    const getCostosPropuestaById = async id => {
        const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getCostosByIdPropuesta/${id}`;
        const { data } = await axios.get(url);
        let costos = data.data.map( cost => {
            cost.idTipoCosto = cost.idTipoCosto.id
            return cost;
        } )
        setCostosPropuestas(costos);
    }

  return (
    <div>
        <Form validated={validated}>

        <Row>
            <Col md={12} lg={6}>
                <Card>
                    <div className='m-3'>
                        <h5>Datos de Propuesta</h5>
                    </div>

                        <Form.Group  className="m-4">
                            <Form.Label>Estado:</Form.Label>
                            <br/>
                            <div className='text-center '>
                                { tiposEstados.map( (estado) => {
                                                
                                    let check = false
                                    if( estado.id === idEstadoPropuesta ) check = true

                                    if( estado.id)
                                    
                                    return (<Form.Check
                                        disabled={formDisabled}
                                        key={estado.id}
                                        inline
                                        label={capitalizarString(estado.nombre)}
                                        name="type"
                                        type={'radio'}
                                        id={estado.id}
                                        defaultChecked={check}
                                        onChange={ e => {
                                            setIdEstadoPropuesta(e.target.id)
                                        }}
                                        required
                                    />)
                                }) }
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Por favor seleccionar Estado.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="m-4">
                            <Form.Label>Nombre de la Propuesta:</Form.Label>
                            <Form.Control 
                                disabled={formDisabled}
                                autoComplete="off"
                                type="text" 
                                name="nombre"
                                required
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingresar Nombre de la Propuesta.
                            </Form.Control.Feedback>              
                        </Form.Group>
                        
                        <Form.Group className="m-4">
                            <Form.Label>Descripción:</Form.Label>
                            <Form.Control 
                            disabled={formDisabled}
                                autoComplete="off"
                                as="textarea" 
                                rows={3} 
                                name="descripcion"
                                value={descripcion}
                                onChange={e => setDescripcion(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingresar Descripción.
                            </Form.Control.Feedback>              
                        </Form.Group>

                        <Form.Group className="mb-4 m-4">
                            <Form.Label>Duración (Días):</Form.Label>
                            <Form.Control
                                disabled={formDisabled}
                                autoComplete="off"
                                value={periodo}
                                name="periodo"
                                onChange={e => setPeriodo(e.target.value)}
                                type="number"
                                min={1}
                                pattern="^[0-9]+"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingresar Duración, número entero y positivo sobre 0 (ej: 5)
                            </Form.Control.Feedback> 
                        </Form.Group>
                    {/* </Form> */}
                </Card>
            </Col>

            <Col>
                <Card>
                <div className='m-3'>
                    <h5>Costos de la Propuesta</h5>
                </div>
                    <Card.Body>
                        <AdvanceTableWrapper
                            columns={columnsCostosSinAccion}
                            data={costosPropuestaActions}
                            sortable
                            pagination
                            perPage={10}
                        >
                            <AdvanceTable
                                title='Costos'
                                subtitle={`Tabla de Costos - ${propuestaToSee.nombre}`}
                                searchBar={false}
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                                }}
                            />
                            <AdvanceTableFooter
                                className={'mt-2'}
                                rowCount={costosPropuestaActions.length}
                                table
                                rowInfo
                                navButtons
                            />
                        </AdvanceTableWrapper>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </Form>
    </div> 
  )
}
