 import React, { useState, useEffect } from 'react';

import { Card, Row, Col, Modal, Button, Spinner } from 'react-bootstrap'
import axios from 'utils/axios';
import { showAlertaTokenExpired, toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';

// TABLA
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';

import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';

// COMPONENTES
import BitacoraEntidad from '../components/BitacoraEntidad'
import BitacoraData from '../components/BitacoraData.js'
import ModalDesabilitados from './components/ModalDesabilitados';
import ModalTamañoEquipo from './components/ModalTamañoEquipo';

import { columnsSizeEquipos } from '../data';
import useLoadingContext from 'hooks/useLoadingContext';

const TamañoEquiposAdmin = () => {
  const navigate = useNavigate();

  const {user} = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

    //! Bitacoras
    const [ idEntidad, setIdEntidad ] = useState(28);
    const [ idBitacoraData, setIdBitacoraData ] = useState([]);

  //! GENERAL
  const [ tamañosEquipos, setTamañosEquipos ] = useState([]);
  const [ tamañosToTable, setTamañosToTable ] = useState([]);
  const [ readyContent, setReadyContent ] = useState(false);

  //! Modales
  const [ showModalAddEdit, setShowModalAddEdit ] = useState(false);
  const [ showModalDisabled, setShowModalDisabled ] = useState(false);
  const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false);
  const [ showBitacoraData, setShowBitacoraData ] = useState(false);
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);

  //! TO ACTIONS
  const [ sizeToUpdate, setSizeToUpdate ] = useState({});
  const [ idToUpdate, setIdToUpdate ]  = useState(0);
  const [ idToDelete, setIdToDelete ]  = useState(0);

  //? INICIANDO LA APP
  useEffect( () => {
    const hasAccess = verifyAccess(user, 'profileSizeEquiposAdmin', '/administrador/dashboard');
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) startResetApp();
  }, []);

  const startResetApp = () => {
    setShowModalAddEdit(false);
    setShowModalDisabled(false);
    setShowBitacoraEntidad(false);
    setShowBitacoraData(false);
    setShowDeleteModal(false);

    Promise.all([ getAllTamaños() ])
          .then(([ tamaños ]) => {
            setReadyContent(true)
          })
          .catch(error => {
            toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
            setReadyContent(true)
          })
  }

  useEffect( () => {
    if( tamañosEquipos.length < 0 ) return;
    const tamañosToTable = tamañosEquipos.map( size => {
      size.descripcion = size.descripcion;
      size.acciones = [
        <IconButton
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
              setShowModalAddEdit(true);
              setSizeToUpdate(size);
              setIdToUpdate(size.id);
          }}
        ></IconButton>,
        <IconButton
          title="Deshabilitar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
              setIdToDelete(size.id);
              setShowDeleteModal(true);
          }}
        ></IconButton>,
        <IconButton
          title="Ver Bitácora"
          variant="white"
          size="sm"
          icon="book"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={ () => {
            setIdBitacoraData(size.id);
              setShowBitacoraData(true);
          }}
        ></IconButton>
      ]
      return size
    } )
    setTamañosToTable(tamañosToTable);
  }, [tamañosEquipos]);


  //! GET
  //? Obtener todos los tamaños de los equipos
  const getAllTamaños = async () => {
    setReadyContent(false)
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/getAllSizeEquipos`;
    try {
      const { data } = await axios.get(url);
      if( !data || data === undefined || data.statusCode === 401 ) {
        showAlertaTokenExpired();
        return;
      }
      if( data.statusCode == 200 ) setTamañosEquipos(data.data);
      setReadyContent(true)
    } catch (error) {
      console.error('Ha ocurrido un error interno al intentar consultar los tamaños de los equipos');
      setReadyContent(true)
    }
  }

  //! POST
  //? Agrega un nuevo tamaño de equipo
  const createNewSizeEquipo = async (size) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/createNewSizeEquipo`;
    try {
      setIsLoading(true);
      const { data } = await axios.post(url, size);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 2) ) return;
      if( data.statusCode == 201 ) toastNotification('success', 'Tamaño de equipo creado correctamente');
      if( data.statusCode !== 201 ) toastNotification('warning', 'Ocurrió un error al intentar crear tamaño de equipo');
    } catch (error) {
      setIsLoading(false);
      toastNotification('error', 'Ha ocurrido un error interno al intentar agregar un nuevo tamaño de equipo'); 
    }
    startResetApp();
  }

  //! PATCH
  //? Actualiza un tamaño de equipo por su id
  const updateSizeEquipo = async ( size ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/updateSizeEquipo/${size.id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url, size);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if( data.statusCode == 200 ) toastNotification('success', 'Tamaño de equipo editado correctamente');
      if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al intentar editar el tamaño de equipo');
    } catch (error) {
      setIsLoading(false);
      toastNotification('error', 'Ha ocurrido un error interno al intentar actualizar el tamaño del equipo');
    }
    startResetApp();
  }

  //! PATCH
  //? Habilitar el tamaño por un id
  const enableTamañoById = async (id) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/enableSizeEquipo/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if( data.statusCode == 200 ) toastNotification('success', 'Tamaño de equipo habilitado correctamente');
      if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al intentar habilitar el tamaño de equipo');
    } catch (error) {
      setIsLoading(false);
      toastNotification('error', 'Ha ocurrido un error interno al intentar habilitar el tamaño'); 
    }
    startResetApp()
  }

  //! DELETE
  //? Deshabilita el tamaño por un id
  const disableTamañoById = async (id) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/disableSizeEquipo/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.delete(url);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 4) ) return;
      if( data.statusCode == 200 ) toastNotification('success', 'Tamaño de equipo deshabilitado correctamente');
      if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrió un error al intentar deshabilitar el tamaño de equipo');
    } catch (error) {
      setIsLoading(false);
      toastNotification('error', 'Ha ocurrido un error interno al intentar habilitar el tamaño');
    }
    startResetApp();
  }




  return (
    <>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="justify-content-between">
          <Col lg={6} className="ps-lg-4 my-5text-lg-start">
            <h3 className="text-primary">Tamaños de Equipos</h3>
          </Col>
        </Row>

        { !readyContent ? 
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>   
        : (
        <Card className="h-md-100">
          <Card.Body>

          <AdvanceTableWrapper
            columns={columnsSizeEquipos}
            data={tamañosToTable}
            sortable
            pagination
            perPage={10}
          >

          <Row className="flex-end-center mb-3">
            <Col xs={12} lg={4} className='mx-auto mb-2'>
            <div className='text-center'>
                <IconButton
                  variant="falcon-success"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  className="me-2"
                  onClick={() => {
                      setShowModalAddEdit(true)
                  }}
                >
                  Nuevo Tamaño
                </IconButton>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mx-auto mb-2'>
              <div className='text-center'>
                <IconButton
                  variant="falcon-primary"
                  size="sm"
                  icon="book"
                  transform="shrink-3"
                  className="me-2"
                  onClick={() => {
                      setShowBitacoraEntidad(true);
                  }}
                >
                  Bitácora General
                </IconButton>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mx-auto mb-2'>
              <div className='text-center'>
                <IconButton
                  variant="falcon-warning"
                  size="sm"
                  icon="trash"
                  transform="shrink-3"
                  className="me-2"
                  onClick={() => {
                    setShowModalDisabled(true)
                  }}
                >
                  Deshabilitados
                </IconButton>
              </div>
            </Col>
          </Row>
          
          <AdvanceTable
            title='Tamaños de Equipo'
            subtitle='Tabla Tamaños de Equipos'
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={tamañosToTable.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
          </Card.Body>
        </Card>
        ) }
      </Card.Body>


    <ModalTamañoEquipo  showModalAddEdit={showModalAddEdit} setShowModalAddEdit={setShowModalAddEdit} idToUpdate={idToUpdate} setIdToUpdate={setIdToUpdate} tamañoToUpdate={sizeToUpdate} setTamañoToUpdate={setSizeToUpdate} createNewSize={createNewSizeEquipo} updateSize={updateSizeEquipo} />

    <BitacoraEntidad title={'Bitácora General'} idEntidad={idEntidad} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad}/>

    <BitacoraData title={'Bitacora del Dato'} idEntidad={idEntidad} idBitacoraData={idBitacoraData} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData}/>

    <ModalDesabilitados showModalDisabled={showModalDisabled} setShowModalDisabled={setShowModalDisabled} enableData={enableTamañoById} />

    <div>{/* INICIO MODAL PARA ELIMINAR PROPUESTA */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Deshabilitar Tamaño de Equipo</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de deshabilitar el tamaño de equipo?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              setShowDeleteModal(false)
            }}
          >
            Cancelar
          </Button>
          <Button 
            variant="danger"
            disabled={isLoading}
            onClick={() => { 
              disableTamañoById(idToDelete)
              setShowDeleteModal(false)
            }}
          >
            Deshabilitar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}
    
    </>
  )
}

export default TamañoEquiposAdmin