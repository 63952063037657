import React from 'react';
import Section from 'components/common/Section';
import { Row, Col } from 'react-bootstrap';

const FooterStandard = () => {
  return (
    <>
      <Section bg="dark" className="vh-0 pt-2 pb-2 light">
      <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                Todos los derechos reservados{' '}
                <br className="d-sm-none" /> &copy;{' '}
                <a
                  className="text-white opacity-85"
                  href="https://www.promather.cl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Promather S.A{'  '}
                </a>
                | Desarrollado por {' '}
                <a
                  className="text-white opacity-85"
                  href="http://advise.cl/"
                  target="_blank"
                  rel="noopener noreferrer"
                >Advise</a>
              </p>
            </Col>
            <Col xs={12} sm="auto">
            </Col>
          <Col xs={12} sm="auto">
              <p className="mb-0 text-600">v2.9.1</p>
            </Col>
            {/* <Col xs={12} sm="auto">
            <p className="mb-0 text-600">
                Desarrollado por{'  '}
                <a
                  className="text-white opacity-85"
                  href="http://www.advise.cl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Advise
                </a>
              </p>
            </Col> */}
          </Row>
          {/* <Row className="justify-content-between">
          <Col xs={12} sm="auto">
            </Col>
          <Col xs={12} sm="auto">
              <p className="mb-0 text-600">v2.0</p>
            </Col>
          </Row> */}
      </Section>
    </>
  );
};

export default FooterStandard;
