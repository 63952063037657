import { faDownload } from "@fortawesome/free-solid-svg-icons";
import IconButton from "components/common/IconButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import PropTypes from 'prop-types';
import moment from "moment";
import { useEffect, useState } from "react";
import { bytesToMB } from "helpers/utils";
import { ModalPreviewFile } from "./ModalPreviewFile";

const columnsDetalle = [
  {
    accessor: 'name_file',
    Header: 'Nombre Archivo'
  },
  {
    accessor: 'date_file',
    Header: 'Fecha de Subida'
  },
  {
    accessor: 'username_uploads_file',
    Header: 'Usuario'
  },
  {
    accessor: 'size_file_mb',
    Header: 'Peso del Archivo'
  },
  {
    accessor: 'extension',
    Header: 'Extensión'
  },
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
];

// isCrud, nos servirá para indicar que este componente servirá para eliminar los archivos tambien o si es de solo lectura
export const TablaArchivos = ({ archivos = [], isCrud = false, handleDelete }) => {

  const [ archivosToTable, setArchivosToTable ] = useState([]);

  const [ showModalPreview, setShowModalPreview ] = useState(false);
  const [ fileToPreview, setFileToPreview ] = useState({});

  useEffect( () => {    
    if( !isCrud && archivos.length < 1 ) return;
    const archivosRecibidos = JSON.parse(JSON.stringify(archivos));
    
    let archivosToTable = archivosRecibidos.map( archivo => {
      archivo.username_uploads_file = (archivo.user) ? `${archivo.user.name} ${archivo.user.lastName}` : 'Desconocido';
      archivo.size_file_mb = ( archivo.size ) ? bytesToMB(archivo.size) : 'Desconocido';
      archivo.acciones = [
        <IconButton  //Si el archivo no tiene id es porque es temporal y no se puede descargar
          title="Descargar Archivo"
          variant="white"
          size="sm"
          icon={faDownload}
          className={ archivo.id ? '' : 'd-none' }
          style={{ boxShadow: "none", width: '50%' }}
          onClick={() => downLoadFile(archivo.id)}
        ></IconButton>,
        <IconButton  //Si el archivo no tiene id es porque es temporal y no se puede descargar
          title="Previsualizar"
          variant="white"
          size="sm"
          icon="eye"
          className={ archivo.id ? '' : 'd-none' }
          style={{ boxShadow: "none", width: '50%' }}
          onClick={() => { setShowModalPreview(true); setFileToPreview(archivo) }}
        ></IconButton>,
        <IconButton
          title="Eliminar Archivo"
          variant="white"
          size="sm"
          icon='trash-alt'
          className={ isCrud ? '' : 'd-none' }
          style={{ boxShadow: "none", width: '50%'}}
          onClick={() => handleDelete(archivo)}
        ></IconButton>
      ]
      archivo.date_file = moment(archivo.date_file).format('DD/MM/YYYY');
      return archivo;
    } );

    setArchivosToTable(archivosToTable);
  }, [ archivos ] );


  const downLoadFile = ( idArchivo ) => {
    window.open(`${process.env.REACT_APP_EQUIPO_SERVICE}/file/downloadFileById/${idArchivo}`, '_blank');
  }
  

  return (

    <>
      { archivos.length < 1 
        ? (
          <p className="text-center my-3">No se han encontrado archivos</p>
        ) 
        : (
          <AdvanceTableWrapper
            columns={ columnsDetalle }
            data={archivosToTable}
            sortable
            pagination
            perPage={1000000}
          >
            <AdvanceTable
              title="Archivos"
              subtitle="Tabla de Archivos"
              searchBar={false}
              btnExport={false}
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />

          </AdvanceTableWrapper>
        )}

      <ModalPreviewFile key={'previewModal'} showModal={showModalPreview} setShowModal={setShowModalPreview} file={fileToPreview} />

    </>

  )
}

TablaArchivos.propTypes = {
  archivos: PropTypes.array.isRequired, // Archivos a mostrar en la tabla
  isCrud: PropTypes.bool, // Indica si estará en habilitado para eliminar archivos
  handleDelete: PropTypes.func, // Funcion para eliminar archivos
};